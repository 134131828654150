import dynamic from 'next/dynamic';

import { getStoriesBySlug } from '@helpers/posts';
import { getUsers } from '@helpers/users';
import { mergeCardUsersData, limitUsersData } from '@helpers/users';
import { getTalentTypeAndSmeVerticalPage } from '@cms/talentTypeAndSmeVertical';
import HomePage from '../templates/home';
// const HomePage = dynamic(() => import('../templates/home'), { ssr: false });

const MultiPage = ({ ...props }) => {
  return <HomePage {...props} />;
};

export default MultiPage;

export const getStaticProps = async () => {
  let postData,
    apiUsers = [];

  const { homePage: page } = await getTalentTypeAndSmeVerticalPage(`/`);

  if (!page) {
    return {
      notFound: true,
      revalidate: 500,
      props: {
        error: {
          statusCode: 404,
          message: 'Page Not Found',
        },
      },
    };
  }
  // Get Users From Page
  if (page.users.length) {
    const users = page.users.map((t) => t.uuid);
    apiUsers = await getUsers(users);
  }

  if (page.articles) {
    postData = await getStoriesBySlug(page.articles);
  }

  const cardUsers = limitUsersData(
    mergeCardUsersData(
      [],
      page.users.map((e) => {
        return { uuid: e.uuid };
      }),
      apiUsers
    )
  );

  return {
    props: { ...page, postData, cardUsers },
    revalidate: 500,
  };
};
