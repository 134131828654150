import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import LoadingArea from '@hiredigital/ui/LoadingArea';
import MainLayout from '@components/Layout/Layout';
import MainHero from '@components/PageComponents/Hero/MainHero/MainHero';
import TalentTypes from '@components/PageComponents/TalentTypes/TalentTypes';
import LogoSection from '@components/PageComponents/LogoSection/LogoSection';

const WhyWork = dynamic(() => import('@components/PageComponents/WhyWork/WhyWork'), {
  loading: () => <LoadingArea height={671} />,
  ssr: false,
});

const PrioritiesSection = dynamic(
  () => import('@components/PageComponents/PrioritiesSection/PrioritiesSection'),
  {
    loading: () => <LoadingArea height={3019} />,
    ssr: false,
  }
);

const PostSection = dynamic(() => import('@components/PageComponents/PostSection/PostSection'), {
  loading: () => <LoadingArea height={798} />,
  ssr: false,
});

const GenericCta = dynamic(() => import('@components/PageComponents/CallToAction/GenericCta'), {
  ssr: false,
});
const CallToAction = dynamic(() => import('@components/PageComponents/CallToAction/CallToAction'), {
  ssr: false,
});
const Workflow = dynamic(() => import('@components/PageComponents/Workflow/CompanyWorkflow'), {
  ssr: false,
});
const PressSection = dynamic(() => import('@components/PageComponents/PressSection/PressSection'), {
  ssr: false,
});

const RightTriangle = dynamic(() => import('@components/Parallax/RightTriangle'), {
  ssr: false,
});
const LeftTriangle = dynamic(() => import('@components/Parallax/LeftTriangle'), {
  ssr: false,
});
const Home = ({
  head,
  heroTitle,
  heroDescription,
  ctaTitle,
  ctaDescription,
  ctaButtonLabel,
  ctaButtonLink,
  hero,
  footer,
  midCtaSection,
  clients,
  cardUsers,
  postData,
  directory,
  ...props
}) => {
  return (
    <MainLayout footer={footer} pageHead={head}>
      <MainHero
        customerNames={clients}
        users={cardUsers}
        title={heroTitle}
        description={heroDescription}
        talentCtaLinkTo={`/talent/register`}
        talentCtaLabel={`Apply As a Talent`}
        brandCtaLinkTo={`/schedule-call`}
        brandCtaLabel={`Work With Talent`}
      />
      <LogoSection logos={clients} />
      <RightTriangle />
      <TalentTypes />
      <LeftTriangle />
      <WhyWork />

      <GenericCta
        title={ctaTitle}
        description={ctaDescription}
        linkTo={ctaButtonLink}
        buttonLabel={ctaButtonLabel}
      />
      <RightTriangle />

      <Workflow />
      <PressSection />
      <PrioritiesSection data={directory} />
      <PostSection postData={postData} />
      <CallToAction />
    </MainLayout>
  );
};

Home.propTypes = {
  hero: PropTypes.object,
  head: PropTypes.object,
  footer: PropTypes.object,
  midCtaSection: PropTypes.object,
  clients: PropTypes.array,
  cardUsers: PropTypes.array,
  posts: PropTypes.array,
  postData: PropTypes.array,
};
export default Home;
